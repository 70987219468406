<template>
  <div class="tc-user_info_statistical">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="科室部门" prop="did">
            <el-select v-model="formInline.did">
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="时间段" prop="date">
            <!-- <el-date-picker
              v-model="formInline.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            ></el-date-picker> -->

            <!-- 开始 -->
            <div class="twoData">
              <el-date-picker
                v-model="formInline.stime"
                type="date"
                placeholder="选择日期"
                value-format="timestamp"
              >
              </el-date-picker>
              至
              <!-- 结束 -->
              <el-date-picker
                v-model="formInline.etime"
                type="date"
                placeholder="选择日期"
                value-format="timestamp"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="query">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 图表 -->
    <div class="statistical_chart">
      <div class="row1">
        <div class="row1-col1">
          <div
            id="chart-sex-data2"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
        <div class="row1-col2">
          <div
            id="chart-profession-data"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
        <div class="row1-col3">
          <div
            id="chart-culture-data"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
      </div>
      <div class="row2">
        <div class="row2-col1">
          <div
            id="chart-age-data2"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
        <div class="row2-col2">
          <div
            id="chart-incomeData-data"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
        <div class="row2-col3">
          <div
            id="chart-marriage-data"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadDepartmentData } from "@/api/comm.js";
import { getUserStatistics } from "@/api/user_info_statistical.js";
export default {
  data() {
    return {
      formInline: {
        date: [],
        etime: "",
        stime: "",
        did: "",
      },
      sex: "",
      age: "",
      // 部门
      departmentList: [],
      culture: [],
      SexNum: 0,
      //职业
      occupation: {
        name: [],
        nan: [],
        nv: [],
      },
      income: {}, //收入
      marriage: {}, //婚姻
    };
  },
  created() {
    // 发送图表数据请求
    this.loadEchartsData();
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
  },
  mounted() {
    // 延迟1秒对图表进行加载
    // setTimeout(() => {
    //   this.$nextTick(() => {
    this.charProfessionData();
    this.charIncomeData();
    this.charMarriageData();
    //   });
    // }, 1000);
  },
  methods: {
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
        this.formInline.stime = "";
        this.formInline.etime = "";
      });
    },
    // 查询
    query() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      // 清空数据
      data.stime = data.stime / 1000;
      data.etime = data.etime / 1000;
      delete data.date;
      this.$nextTick(() => {
        this.loadEchartsData(data);
      });
      // 发送请求
    },
    // 发送图表数据请求
    loadEchartsData(val) {
      // // 查询请求
      getUserStatistics(val).then((res) => {
        //console.log(res);
        // -----------------处理数据----------------------
        this.age = res.data.age;
        // 性别
        this.sex = res.data.sex;
        console.log(this.sex);
        if (this.sex.length == 0) {
          this.SexNum = 0;
          this.sex = [
            { num: 0, sex: 0 },
            { num: 0, sex: 0 },
          ];
        } else {
          this.SexNum = this.sex[0].num + this.sex[1].num;
        }
        // 文化程度
        let culturetmp = res.data.culture;
        let culture1 = [];
        let culture2 = [];
        culturetmp.forEach((v) => {
          culture1.push(v.culture);
          culture2.push(v.num);
        });
        this.culture[0] = culture1;
        this.culture[1] = culture2;
        // 职业
        this.occupation = {
          name: [],
          nan: [],
          nv: [],
        };
        res.data.profession.forEach((v, k) => {
          this.occupation.name.push(v.value);
          this.occupation.nan.push(v.nan);
          this.occupation.nv.push(v.nv);
        });
        // 收入
        this.income = {
          name: [],
          nan: [],
          nv: [],
        };
        res.data.income.forEach((v, k) => {
          this.income.name.push(v.value);
          this.income.nan.push(v.nan);
          this.income.nv.push(v.nv);
        });
        // 婚姻
        this.marriage = {
          name: [],
          nan: [],
          nv: [],
        };
        res.data.marriage.forEach((v, k) => {
          this.marriage.name.push(v.value);
          this.marriage.nan.push(v.nan);
          this.marriage.nv.push(v.nv);
        });
        // 开始渲染-渲染
        this.charCultureData(); //文化
        this.charProfessionData();
        this.charIncomeData();
        this.charMarriageData();
        this.charAgeData();
        this.charSexData();
      });
    },
    // 评测性别数据
    charSexData() {
      // 基于准备好的dom，初始化echarts实例
      let sexData = this.$echarts.init(
        document.getElementById("chart-sex-data2")
      );
      // 绘制图表
      sexData.setOption({
        title: {
          text: "测评性别数据",
          subtext: `总人数\n${this.SexNum}`,
          x: "center",
          y: "10",
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
            color: "#0A0A0A",
            fontWeight: "bold",
          },
          itemGap: 145,
        },
        backgroundColor: "#FFFFFF",
        tooltip: {
          trigger: "item",
        },
        legend: {
          itemWidth: 14,
          itemHeight: 5,
          data: ["男", "女"],
          x: "70%",
          y: "10%",
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["25%", "60%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: (params) => {
                  var colorList = ["#A7D4F6", "#90D97F", "#757575"];
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{a|{b}：{d}%}\n{hr|}",
              rich: {
                hr: {
                  backgroundColor: "t",
                  borderRadius: 2,
                  width: 3,
                  height: 3,
                  padding: [3, 3, 0, -12],
                },
                a: {
                  padding: [-30, 15, -20, 15],
                },
              },
            },
            labelLine: {
              normal: {
                length: 15,
                length2: 20,
                lineStyle: {
                  width: 2,
                },
              },
            },
            data: [
              {
                name: "男",
                value: this.sex[1].num,
              },
              {
                name: "女",
                value: this.sex[0].num,
              },
            ],
          },
        ],
      });
    },
    // 测评年龄
    charAgeData() {
      // 基于准备好的dom，初始化echarts实例
      let ageData = this.$echarts.init(
        document.getElementById("chart-age-data2")
      );
      // 绘制图表
      ageData.setOption({
        title: {
          text: "评测年龄分布",
          textStyle: {
            align: "center",
            // color: "red",
            fontSize: 20,
          },
          left: "center",
          y: "10px",
        },
        backgroundColor: "#FFFFFF",
        tooltip: {
          trigger: "axis",
          confine: true,
          position: function ([x, y]) {
            var newX = x + 200;
            ////console.log(newX)
            return [newX, y];
          },
          formatter: function (params) {
            //console.log(params);
          },
          padding: [
            11, // 上
            19, // 右
            13, // 下
            13, // 左
          ],
        },
        radar: {
          center: ["50%", "55%"],
          radius: "65%",
          name: {
            textStyle: {
              color: "#3D4D65",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#030303",
              width: 3,
              type: "solid",
            },
          },
          splitLine: {
            lineStyle: {
              color: ["#515151"],
              width: 1,
              type: "dashed",
            },
          },
          splitArea: {
            areaStyle: {
              color: [
                "rgba(255, 255, 255, 1)",
                "rgba(255, 255, 255, 0)",
                "rgba(255, 255, 255, 0)",
                "rgba(255, 255, 255, 0)",
              ],
              shadowColor: "#fff",
            },
          },
          indicator: [
            {
              name: this.age.explain[0],
              max: this.age.num[0] + 10,
            },
            {
              name: this.age.explain[1],
              max: this.age.num[1] + 10,
            },
            {
              name: this.age.explain[2],
              max: this.age.num[2] + 10,
            },
            {
              name: this.age.explain[3],
              max: this.age.num[3] + 10,
            },
            {
              name: this.age.explain[4],
              max: this.age.num[4] + 10,
            },
            {
              name: this.age.explain[5],
              max: this.age.num[5] + 10,
            },
          ],
        },
        grid: {
          top: " ",
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: this.age.num,
                name: "数据",
                itemStyle: {
                  normal: {
                    borderWidth: 2,
                    color: "#26A0FF",
                  },
                },
                areaStyle: {
                  color: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.66,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#00F5FF", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#00B2EE", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
            ],
            label: {
              normal: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
                color: "#333",
                position: "insideTopRight",
              },
            },
          },
        ],
      });
    },
    // 文化统计
    charCultureData() {
      let cultureData = this.$echarts.init(
        document.getElementById("chart-culture-data")
      );
      cultureData.setOption({
        backgroundColor: "#FFFFFF",
        title: {
          text: "人员文化统计",
          textStyle: {
            fontSize: 20,
          },
          x: "center",
          y: "10px",
        },
        tooltip: {
          trigger: "axis",
          padding: [8, 10],
          backgroundColor: "rgba(0,0,0,0.5)",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff",
            },
          },
        },
        // legend: {
        //   itemWidth: 14,
        //   itemHeight: 5,
        //   data: ["男", "女"],
        //   top: "9%",
        //   x: "80%",
        //   y: "10%"
        // },
        grid: {
          // top: "20%",
          // containLabel: true,
          bottom: "10%",
          left: "18%",
          right: "5%",
        },
        label: {
          show: true,
          position: "right",
          color: "#333",
          fontSize: 14,
          fontWeight: 700,
        },
        yAxis: [
          {
            type: "category",

            data: this.culture[0],
            axisLabel: {
              // rotate: 30,
              show: true,
              textStyle: {
                color: "#333",
                fontSize: 12,
                fontWeight: 200,
              },
            },
          },
        ],
        xAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "男",
            type: "bar",
            data: this.culture[1],
            barWidth: 15, //柱子宽度
            barGap: "0%", //柱子之间间距
            itemStyle: {
              normal: {
                color: "#AB84FA",
                opacity: 1,
                barBorderRadius: [0, 20, 20, 0],
              },
            },
          },
          // {
          //   name: "女",
          //   type: "bar",
          //   data: [10, 24, 5, 24, 16],
          //   barWidth: 10,
          //   barGap: "0%",
          //   itemStyle: {
          //     normal: {
          //       color: "#60BDD5",
          //       opacity: 1,
          //       barBorderRadius: [0, 20, 20, 0]
          //     }
          //   }
          // }
        ],
      });
    },
    // 职业统计
    charProfessionData() {
      let professioneData = this.$echarts.init(
        document.getElementById("chart-profession-data")
      );
      professioneData.setOption({
        backgroundColor: "#FFFFFF",
        title: {
          text: "测试人员职业统计",
          //subtext: '纯属虚构',
          x: "center",
          //y:'center',
          padding: [12, 0, 0, 0],
          textStyle: {
            fontSize: 20,
            fontWeight: "bolder",
            color: "#333",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 5,
          data: ["男", "女"],
          x: "80%",
          y: "10%",
          padding: [0, 0, 0, 0],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "25%",
          containLabel: true,
        },
        //color:['#ff7f50', '#87cefa'],
        xAxis: [
          {
            type: "category",
            data: this.occupation.name,
          },
        ],
        yAxis: [
          {
            // name: "单位(人)",
            type: "value",
            interval: 150,
          },
        ],
        series: [
          {
            name: "男",
            type: "bar",
            //stack: '签约',
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#4285EC" }, //柱图渐变色               //柱图渐变色
                  { offset: 1, color: "#0BBAFB" }, //柱图渐变色
                ]),
              },
            },
            label: {
              normal: {
                show: true,
                position: "top" /*,
					formatter: "{c}人"*/,
              },
            },
            data: this.occupation.nan,
          },
          {
            name: "女",
            type: "bar",
            barWidth: 15,
            //stack: '签约',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#EC8B6B" }, //柱图渐变色               //柱图渐变色
                  { offset: 1, color: "#ED6E9F" }, //柱图渐变色
                ]),
              },
            },
            label: {
              normal: {
                show: true,
                position: "top" /*,
					formatter: "{c}人"*/,
              },
            },
            data: this.occupation.nv,
          },
        ],
      });
    },
    // 收入统计
    charIncomeData() {
      let incomeData = this.$echarts.init(
        document.getElementById("chart-incomeData-data")
      );
      incomeData.setOption({
        backgroundColor: "transparent",
        title: {
          text: "人员收入统计",
          textStyle: {
            fontSize: 20,
          },
          x: "center",
          y: "10px",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#57617B",
            },
          },
        },
        legend: {
          icon: "rect",
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: ["男", "女"],
          right: "4%",
          x: "80%",
          y: "10%",
          textStyle: {
            fontSize: 12,
            color: "black",
          },
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
            data: this.income.name,
          },
          {
            axisPointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
            axisTick: {
              show: false,
            },

            position: "bottom",
            offset: 20,
            data: [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              {
                value: "月收入 X 万 元",
                textStyle: {
                  fontSize: 13,
                  align: "left",
                },
              },
            ],
          },
        ],
        yAxis: [
          {
            // max:20,
            type: "value",
            // name: "单位（%）",
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 14,
              },
            },
            splitLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
          },
        ],
        series: [
          {
            name: "男",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(18,162,252, 0.3)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(18,162,252, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(18,162,252)",
                borderColor: "rgba(18,162,252,0.3)",
                borderWidth: 12,
              },
            },
            data: this.income.nan,
          },
          {
            name: "女",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(240,72,134, 0.3)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(240,72,134, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(240,72,134)",
                borderColor: "rgba(240,72,134,0.5)",
                borderWidth: 12,
              },
            },
            data: this.income.nv,
          },
        ],
      });
    },
    // 婚姻状态统计
    charMarriageData() {
      let marriageData = this.$echarts.init(
        document.getElementById("chart-marriage-data")
      );
      marriageData.setOption({
        backgroundColor: "#FFFFFF",
        title: {
          text: "婚姻状况统计",
          //subtext: '纯属虚构',
          x: "center",
          //y:'center',
          padding: [12, 0, 0, 0],
          textStyle: {
            fontSize: 20,
            fontWeight: "bolder",
            color: "#333",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 5,
          data: ["男", "女"],
          x: "80%",
          y: "10%",
          padding: [0, 0, 0, 0],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "25%",
          containLabel: true,
        },
        //color:['#ff7f50', '#87cefa'],
        xAxis: [
          {
            type: "category",
            data: this.marriage.name,
          },
        ],
        yAxis: [
          {
            // name: "单位(人)",
            type: "value",
            interval: 150,
          },
        ],
        series: [
          {
            name: "男",
            type: "bar",
            //stack: '签约',
            barWidth: 25,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(119,115,189,0.7)" }, //柱图渐变色               //柱图渐变色
                  { offset: 1, color: "rgba(119,115,189,1)" }, //柱图渐变色
                ]),
              },
            },
            label: {
              normal: {
                show: true,
                position: "top" /*,
					formatter: "{c}人"*/,
              },
            },
            data: this.marriage.nan,
          },
          {
            name: "女",
            type: "bar",
            barWidth: 25,
            //stack: '签约',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(115,203,91,0.7)" }, //柱图渐变色               //柱图渐变色
                  { offset: 1, color: "rgba(115,203,91,1)" }, //柱图渐变色
                ]),
              },
            },
            label: {
              normal: {
                show: true,
                position: "top" /*,
					formatter: "{c}人"*/,
              },
            },
            data: this.marriage.nv,
          },
        ],
      });
    },
  },
  watch: {
    sex: function () {},
    age: function () {},
    culture: function () {},
  },
};
</script>

<style lang="less">
.tc-user_info_statistical {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(2) .el-input__inner {
        width: 230px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  // 图表
  .statistical_chart {
    width: 100%;
    height: calc(~"100% - 20px");
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .row1 {
      flex: 1;
      margin-bottom: 10px;
      display: flex;
      .row1-col1 {
        flex: 1;
        background: #ffff;
        margin-right: 10px;
        border: 1px #b9b9b9 solid;
      }
      .row1-col2 {
        flex: 1;
        background: #ffff;
        margin-right: 10px;
        border: 1px #b9b9b9 solid;
      }
      .row1-col3 {
        flex: 1;
        background: #ffff;
        border: 1px #b9b9b9 solid;
      }
    }
    .row2 {
      flex: 1;
      display: flex;
      .row2-col1 {
        flex: 1;
        background: #ffff;
        margin-right: 10px;
        border: 1px #b9b9b9 solid;
      }
      .row2-col2 {
        flex: 1;
        background: #ffff;
        margin-right: 10px;
        border: 1px #b9b9b9 solid;
      }
      .row2-col3 {
        flex: 1;
        background: #ffff;
        border: 1px #b9b9b9 solid;
      }
    }
  }
  .twoData {
    display: flex;
    .el-date-editor {
      width: unset;
      .el-input__inner {
        width: 140px !important;
      }
    }
  }
}
</style>